import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from 'src/components/Market';
import BackIcon from 'src/svgs/MarketBackIcon';
import CloseIcon from 'src/svgs/MarketCloseIcon';
import React, { ReactElement } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MoreMenu from 'src/pages/TranscriptViewPage/components/MoreMenu/MoreMenu';
import {
  Medium,
  UnitDedicatedNumber,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { i18nPhoneNumber } from 'src/i18n/formatValues';
import SmsIcon from 'src/svgs/SmsIcon';
import EmailIcon from 'src/svgs/EmailIcon';
import CaretIcon from 'src/svgs/CaretIcon';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import './TranscriptViewHeader.scss';
import MessagesPlusSuccessBanner from 'src/components/MessagesPlusSuccessBanner/MessagesPlusSuccessBanner';

const ADD_TO_DIRECTORY_BUTTON_ID = 'TranscriptViewHeader__add-button';

/**
 * The contents of the header for the Transcript View.
 */
const TranscriptViewHeader = observer((): ReactElement => {
  const { t } = useTranslation();
  const {
    navigation,
    transcriptView,
    user,
    featureFlag,
    event,
    subscription,
    tooltip,
    modal,
  } = useMessengerControllerContext();
  const { transcript, title } = transcriptView;

  const unitName = user.units.get(transcript.sellerKey)?.name;

  let mediumIcon = null;
  let secondaryTitle: string | null = null;
  switch (transcript.medium) {
    case Medium.SMS:
      mediumIcon = <SmsIcon />;
      secondaryTitle = i18nPhoneNumber(
        transcript.contactId || '',
        user.countryCode,
      );
      break;
    case Medium.EMAIL:
      mediumIcon = <EmailIcon />;
      secondaryTitle = transcript.contactId || null;
      break;
    default:
      break;
  }

  if (secondaryTitle === title) {
    // If the secondary title is the same as the title, don't bother showing it
    secondaryTitle = null;
  }

  if (!navigation.secondary.isOpen) {
    if (!secondaryTitle) {
      secondaryTitle = unitName ?? null;
    } else if (unitName) {
      secondaryTitle = `${secondaryTitle} | ${unitName}`;
    }
  }

  const showToggleCustomerBladeButton = navigation.secondary.isOpen;
  const showAddToDirectoryButton =
    navigation.secondary.isOpen && transcript.type === 'ORPHAN';

  return (
    <>
      <MarketHeader
        className="MessagesPageHeader TranscriptViewHeader__header"
        onClick={(e) => {
          // This is a workaround to a bug where a market-header that has the
          // compact property and slotted actions will have its elements not receive
          // user events because all the elements for the compact mode are cloned
          // and does not copy over the event listeners. Therefore, we cannot put the
          // click handler on the button itself.
          // https://github.com/squareup/market/issues/7002
          const target = e.target;
          if (
            showAddToDirectoryButton &&
            target instanceof HTMLElement &&
            target.id === ADD_TO_DIRECTORY_BUTTON_ID
          ) {
            modal.openCreateCustomerSuggestionModal({
              firstName: transcript.displayName.maybeFirstName,
              lastName: transcript.displayName.maybeLastName,
              phoneNumber:
                transcript.medium === Medium.SMS
                  ? transcript.contactId
                  : undefined,
              email:
                transcript.medium === Medium.EMAIL
                  ? transcript.contactId
                  : undefined,
            });
          }
        }}
        compact
      >
        {!navigation.secondary.isOpen && (
          <MarketButton
            slot="navigation"
            className="TranscriptViewHeader__navigation-button"
            aria-label={
              navigation.primary.canNavigateBack
                ? t('common.back')
                : t('common.close')
            }
            onClick={() => {
              navigation.primary.navigateBack();
            }}
          >
            {navigation.primary.canNavigateBack ? (
              <BackIcon slot="icon" />
            ) : (
              <CloseIcon slot="icon" />
            )}
          </MarketButton>
        )}
        <div className="TranscriptViewHeader__icon-container">
          {navigation.secondary.isOpen && (
            <div className="TranscriptViewHeader__icon">{mediumIcon}</div>
          )}
          <div className="TranscriptViewHeader__title-container">
            <h2 className="TranscriptViewHeader__title">{title}</h2>
            {secondaryTitle !== null && (
              <p
                className="TranscriptViewHeader__secondary-title"
                data-testid="TranscriptViewHeader__secondary-title"
              >
                {secondaryTitle}
              </p>
            )}
          </div>
          {showAddToDirectoryButton && (
            <MarketButton rank="secondary" id={ADD_TO_DIRECTORY_BUTTON_ID}>
              {t('AddCustomerPage.add')}
            </MarketButton>
          )}
        </div>
        <div
          slot="actions"
          className="TranscriptViewHeader__dropdown-button-container"
        >
          {unitName && navigation.secondary.isOpen && (
            <p className="TranscriptViewHeader__unit-name">{unitName}</p>
          )}
          <MoreMenu transcript={transcript} />
          {showToggleCustomerBladeButton && (
            <MarketButton
              slot="trigger"
              className={`TranscriptViewHeader__toggle-customer-blade-button${
                !navigation.tertiary.isOpen
                  ? ' TranscriptViewHeader__customer-blade-is-closed'
                  : ''
              }`}
              onClick={() => {
                if (navigation.tertiary.isOpen) {
                  event.track('Click Close Customer Blade');
                  navigation.tertiary.close();
                  return;
                }
                event.track('Click Open Customer Blade');
                navigation.tertiary.open();
              }}
            >
              <CaretIcon slot="icon" />
            </MarketButton>
          )}
        </div>
      </MarketHeader>
      {!subscription.isEligibleForSquareOne &&
        featureFlag.get(KEY_MESSAGES_PLUS) &&
        subscription.isUnitSubscribed(transcript.sellerKey) &&
        subscription.isUnitDedicatedStatusRecentlyUpdatedTo(
          transcript.sellerKey,
          [UnitDedicatedNumber.Status.VERIFIED],
        ) &&
        tooltip.isVisible('MESSAGES_PLUS_SUCCESS_BANNER') && (
          <div>
            <MessagesPlusSuccessBanner />
          </div>
        )}
    </>
  );
});

export default TranscriptViewHeader;
