import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MessengerController from './MessengerController';
import MessengerControllerContext from './context/MessengerControllerContext';
import App from './App';
import { SaaSProvider } from '@squareup/saas-shared-ui';
import { Currency } from './gen/squareup/connect/v2/common/money';

type AppContainerProps = {
  messenger: MessengerController;
};

/**
 * The React component root for the Messages Blade.
 *
 * @param {MessengerController} messenger
 */
const AppContainer = observer(
  ({ messenger }: AppContainerProps): ReactElement => {
    return (
      <MessengerControllerContext.Provider value={messenger}>
        <SaaSProvider
          merchantToken={undefined}
          currencyCode={
            (messenger.user.currencyCode as keyof typeof Currency) || 'USD'
          }
          countryCode={messenger.user.countryCode}
          locale={messenger.user.locale}
        >
          <App />
        </SaaSProvider>
      </MessengerControllerContext.Provider>
    );
  },
);

export default AppContainer;
