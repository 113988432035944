import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import FreeTrialExpiringBanner from './components/FreeTrialExpiringBanner';
import SubscriptionExpiringBanner from './components/SubscriptionExpiringBanner';

/**
 * Renders a banner to display if Messages Plus is pending cancellation, be it in
 * free trial, partial cancellation (per unit), or full cancellation.
 *
 * @author klim
 */
const MessagesPlusExpiringBanner = observer((): ReactElement => {
  const { featureFlag, tooltip, subscription, user } =
    useMessengerControllerContext();

  // Indicates that a subset of units were removed from the free trial/active subscription,
  // but the trial/subscription as a whole is still active.
  const unitsAreExpiring =
    !subscription.isExpiring && user.unitsPendingCancellation.length > 0;

  if (
    !featureFlag.get(KEY_MESSAGES_PLUS) ||
    subscription.isEligibleForSquareOne
  ) {
    return <></>;
  }

  if (
    (subscription.isFreeTrialExpiring ||
      (unitsAreExpiring && subscription.isFreeTrial)) &&
    tooltip.isVisible('FREE_TRIAL_EXPIRING_BANNER')
  ) {
    return <FreeTrialExpiringBanner />;
  }

  if (
    subscription.isSubscriptionExpiring &&
    tooltip.isVisible('SUBSCRIPTION_EXPIRING_BANNER')
  ) {
    return (
      <SubscriptionExpiringBanner
        expiryDate={subscription.endAt}
        onDismiss={() => {
          tooltip.dismiss('SUBSCRIPTION_EXPIRING_BANNER');
        }}
      />
    );
  }

  if (
    unitsAreExpiring &&
    !subscription.isFreeTrial &&
    tooltip.isVisible('UNITS_EXPIRING_BANNER')
  ) {
    // Units removed from active paid subscription
    return (
      <SubscriptionExpiringBanner
        expiryDate={subscription.renewalAt}
        onDismiss={() => {
          tooltip.dismiss('UNITS_EXPIRING_BANNER');
        }}
      />
    );
  }

  return <></>;
});

export default MessagesPlusExpiringBanner;
