import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import SessionTimeoutDialog from './components/SessionTimeoutDialog/SessionTimeoutDialog';
import TranscriptViewSkeletonState from './pages/TranscriptViewPage/components/TranscriptViewSkeletonState/TranscriptViewSkeletonState';
import TranscriptsListSkeletonState from './components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import useIsMobile from './hooks/useIsMobile';
import { TranscriptMessengerPage } from './MessengerTypes';
import AppContent from './AppContent';
import SecondaryAppContent from './SecondaryAppContent';
import TertiaryAppContent from './TertiaryAppContent';
import AppOverlays from './AppOverlays';
import SubscriptionErrorScreen from './components/SubscriptionErrorScreen/SubscriptionErrorScreen';
import UnexpectedErrorDialog from 'src/components/UnexpectedErrorDialog/UnexpectedErrorDialog';
import './App.scss';
import './FullPageApp.scss';
import useSaasFeatureStatus from './hooks/useSaasFeatureStatus';
import { useMessengerControllerContext } from './context/MessengerControllerContext';

/**
 * Components that make up the Messages Full Page application.
 *
 * @returns {ReactElement}
 */
const FullPageApp = observer((): ReactElement | null => {
  const isMobile = useIsMobile();
  const {
    navigation,
    transcripts,
    session,
    subscription,
    modal,
    setupFullPageApp,
  } = useMessengerControllerContext();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setupFullPageApp(isMobile).then((isSuccess: boolean) => {
      if (isSuccess) {
        setIsLoading(false);
      }
    });
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSaasFeatureStatus();

  useEffect(() => {
    if (!isLoading && isMobile) {
      // On mobile, hide the secondary view area
      navigation.secondary.close();
    }
    if (!isLoading && !isMobile) {
      navigation.openSecondaryView();
      // Ensure conversations list is shown in the primary view when changing to the secondary view
      navigation.primary.clearNavigation();
      navigation.primary.navigateTo('TRANSCRIPTS_LIST');
    }
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const secondaryPage = navigation.secondary
    .currentPage as TranscriptMessengerPage;
  const transcript = secondaryPage?.transcriptId
    ? transcripts.get(secondaryPage?.transcriptId)
    : undefined;
  const customerTokens = transcript?.customerTokens || [];
  const customerTokensLength = customerTokens.length;
  const firstCustomerToken = customerTokens?.[0];

  useEffect(() => {
    if (
      isMobile ||
      navigation.secondary.currentPageName !== 'TRANSCRIPT_VIEW'
    ) {
      navigation.tertiary.close();
      return;
    }
    navigation.tertiary.openAndClearNavigation();
    if (
      transcript?.id &&
      customerTokensLength === 0 &&
      transcript.status === 'SUCCESS'
    ) {
      navigation.tertiary.navigateTo({
        name: 'CUSTOMER_ADD',
        transcriptId: transcript.id,
      });
      return;
    }
    if (transcript?.id && customerTokensLength === 1) {
      navigation.openCustomerDetailView(transcript.id, firstCustomerToken);
      return;
    }
    if (transcript?.id && customerTokensLength > 1) {
      navigation.tertiary.navigateTo({
        name: 'CUSTOMER_MERGE_VIEW',
        transcriptId: transcript.id,
      });
    }
  }, [
    isMobile,
    transcript?.id,
    transcript?.status,
    customerTokensLength,
    firstCustomerToken,
    navigation,
    navigation.secondary.currentPageName,
  ]);

  if (isMobile) {
    if (isLoading || subscription.status === 'LOADING') {
      return (
        <div
          className="FullPageApp__mobile-container"
          data-testid="FullPageApp__loading"
        >
          <TranscriptsListSkeletonState showHeader />
          {modal.currentModal === 'UNEXPECTED_ERROR' && (
            <UnexpectedErrorDialog />
          )}
        </div>
      );
    }
    if (subscription.status === 'ERROR') {
      return (
        <div className="FullPageApp__mobile-container">
          <SubscriptionErrorScreen />
        </div>
      );
    }
    return (
      <>
        <div className="FullPageApp__mobile-container">
          <AppContent />
        </div>
        <AppOverlays />
        {session.isExpired && <SessionTimeoutDialog />}
      </>
    );
  }

  if (isLoading || subscription.status === 'LOADING') {
    return (
      <div
        className="FullPageApp__root-container"
        data-testid="FullPageApp__loading"
      >
        <div className="FullPageApp__primary-container">
          <TranscriptsListSkeletonState showHeader />
        </div>
        <div className="FullPageApp__secondary-container">
          <TranscriptViewSkeletonState showHeader />
        </div>
        {modal.currentModal === 'UNEXPECTED_ERROR' && <UnexpectedErrorDialog />}
      </div>
    );
  }

  if (subscription.status === 'ERROR') {
    return (
      <div className="FullPageApp__root-container">
        <SubscriptionErrorScreen />
      </div>
    );
  }

  let tertiaryContainerClassName = 'FullPageApp__tertiary-container';
  if (navigation.tertiary.isOpen) {
    tertiaryContainerClassName += ' FullPageApp__tertiary-container-open';
  }
  if (
    navigation.secondary.currentPageName === 'TRANSCRIPT_VIEW' &&
    transcript?.status === 'SUCCESS'
  ) {
    tertiaryContainerClassName += ' FullPageApp__tertiary-container-transition';
  }

  return (
    <>
      <div className="FullPageApp__root-container">
        <div className="FullPageApp__primary-container">
          <AppContent />
        </div>
        <div className="FullPageApp__secondary-container">
          <SecondaryAppContent />
        </div>
        <div className={tertiaryContainerClassName}>
          <TertiaryAppContent />
        </div>
      </div>
      <AppOverlays />
      {session.isExpired && <SessionTimeoutDialog />}
    </>
  );
});

export default FullPageApp;
