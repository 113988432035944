import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketList, MarketSelect } from 'src/components/Market';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import { useTranslation } from 'react-i18next';
import LocationSection from './LocationSection';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type LocationSelectorProps = {
  label: string;
  unitToken: string;
  setUnitToken: (token: string) => void;
  className?: string;
};

/**
 * A dropdown selector that list downs all the active locations. The locations
 * are sorted in alphabetical order, and shows the M+ units (if any) above the rest.
 *
 * @example
 * Basic usage:
 * <LocationSelector
 *   label="Location"
 *   unitToken={unitToken}
 *   setUnitToken={setUnitToken}
 * />
 * @param {string} label
 * The text label to show in the selector.
 * @param {string} unitToken
 * The currently selected unit token.
 * @param {Function} setUnitToken
 * Function to call when a location is selected.
 * @param {string} [className]
 * (Optional) Classname to customize the styling for the component.
 * @author klim
 */
const LocationSelector = observer(
  (props: LocationSelectorProps): ReactElement => {
    const { label, unitToken, setUnitToken, className } = props;
    const { user, subscription } = useMessengerControllerContext();
    const {
      unitsWithMessagesPlus,
      unitsWithoutMessagesPlus,
      unitsWithDedicatedNumbers,
      unitsWithoutDedicatedNumbers,
    } = user;
    const { t } = useTranslation();

    // Hide labels if either M+ or non-M+ is empty
    const isAllUnitsEitherMessagesPlusOrWithoutMessagesPlus =
      unitsWithMessagesPlus.length === 0 ||
      unitsWithoutMessagesPlus.length === 0;
    // Hide labels if either dedicated number or non-dedicated number is empty
    const isAllUnitsEitherDedicatedNumbersOrWithoutDedicatedNumbers =
      unitsWithDedicatedNumbers.length === 0 ||
      unitsWithoutDedicatedNumbers.length === 0;

    const hideUnitSectionLabels =
      subscription.isMessagesFeatureEnabledWithSquareOne
        ? isAllUnitsEitherDedicatedNumbersOrWithoutDedicatedNumbers
        : isAllUnitsEitherMessagesPlusOrWithoutMessagesPlus;

    return (
      <MarketSelect
        className={className}
        data-testid="LocationSelector"
        value={unitToken}
        onClick={(e) => e.stopPropagation()}
        onMarketSelectValueDidChange={(event) => {
          setUnitToken(String(event.detail.value));
        }}
        popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
      >
        <label>{label}</label>
        <MarketList slot="list">
          <LocationSection
            label={
              subscription.isMessagesFeatureEnabledWithSquareOne
                ? t('LocationSelector.dedicated_number_label')
                : t('LocationSelector.messages_plus')
            }
            units={
              subscription.isMessagesFeatureEnabledWithSquareOne
                ? unitsWithDedicatedNumbers
                : unitsWithMessagesPlus
            }
            hideLabel={hideUnitSectionLabels}
          />
          <LocationSection
            label={t('LocationSelector.others')}
            units={
              subscription.isMessagesFeatureEnabledWithSquareOne
                ? unitsWithoutDedicatedNumbers
                : unitsWithoutMessagesPlus
            }
            hideLabel={hideUnitSectionLabels}
          />
        </MarketList>
      </MarketSelect>
    );
  },
);

export default LocationSelector;
