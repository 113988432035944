import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MarketButton,
  MarketEmptyState,
  MarketLink,
  MarketTooltip,
} from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { M_PLUS_FAQ_URL, M_PLUS_SUBSCRIPTION_FAQ_URL } from 'src/utils/url';
import { getReferralPageName } from 'src/utils/transcriptUtils';
import './UpgradeEmptyState.scss';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';

export type UpgradeEmptyStateProps = {
  unitToken?: string;
};

/**
 * An empty state to show when the merchant is unable to proceed without
 * subscribing to Messages Plus, with a CTA to open the upsell sheet.
 * This will be shown if the merchant is not subscribed to Messages Plus,
 * when (non-exhaustive):
 * - No transcripts in list page
 * - Brand-new transcript in view page
 *
 * @param {string} [unitToken]
 * (Optional) The unit token of the transcript being viewed.
 * Do not pass this prop when not in an individual transcript.
 * @author klim, wdetlor
 */
const UpgradeEmptyState = observer(
  ({ unitToken }: UpgradeEmptyStateProps): ReactElement => {
    const { subscription, navigation, event, transcriptView, featureFlag } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const stackButtons = !navigation.secondary.isOpen;

    useEffect(() => {
      if (subscription.isProhibited) {
        event.track('View CBD Null State', {
          referral_page_name: 'conversation_list',
        });
      }
    }, [subscription.isProhibited, event]);

    const showUpgradeInsteadOfSubscribe = subscription.hasPreviouslySubscribed;
    const referralPageName = getReferralPageName({
      isTranscriptView:
        navigation.navStoreForUrl.currentPageName === 'TRANSCRIPT_VIEW',
      isEmpty: transcriptView.transcript.viewItems.length === 0,
      isActive: transcriptView.transcript.isActive,
    });
    const onClick = (): void => {
      event.track('Click Messages Plus Subscribe', {
        referral_page_name: referralPageName,
        free_trial_eligible: !showUpgradeInsteadOfSubscribe,
      });
      if (
        featureFlag.get(KEY_MESSAGES_PLUS) &&
        subscription.isSubscribed &&
        unitToken
      ) {
        navigation.openSheet({
          name: 'UNIT_VERIFICATION_FORM',
          unitToken,
        });
        return;
      }
      navigation.openSheet('MESSAGES_PLUS_PRICING');
    };
    return (
      <MarketEmptyState
        className="UpgradeEmptyState"
        data-testid="UpgradeEmptyState"
      >
        <h3 className="heading-30" slot="primary-text">
          <div className="UpgradeEmptyState__icon">
            <ChatBubblesIcon />
          </div>
          {unitToken
            ? t('MessagesPlus.empty_state.transcript_title')
            : t('MessagesPlus.empty_state.inbox_title')}
        </h3>
        <p className="paragraph-30" slot="secondary-text">
          {unitToken
            ? t('MessagesPlus.empty_state.transcript_description')
            : t('MessagesPlus.empty_state.inbox_description')}
        </p>
        <div slot="actions" className="UpgradeEmptyState__actions-container">
          <div
            className={classNames(
              'UpgradeEmptyState__actions-container__buttons',
              {
                'UpgradeEmptyState__actions-container__buttons__stack':
                  stackButtons,
              },
            )}
          >
            {!subscription.isSubscribed && !subscription.isProhibited && (
              <MarketButton
                rank="secondary"
                href={M_PLUS_FAQ_URL}
                target="_blank"
                data-testid="UpgradeEmptyState__learn-more-button"
              >
                {t('common.learn_more')}
              </MarketButton>
            )}
            <MarketButton
              rank="primary"
              onClick={onClick}
              onKeyDown={(e) => onKeyDownEnter(e, onClick)}
              disabled={subscription.isProhibited || undefined}
            >
              {showUpgradeInsteadOfSubscribe
                ? t('MessagesPlus.subscribe')
                : t('MessagesPlus.try')}
            </MarketButton>
          </div>

          {subscription.isProhibited && (
            <MarketTooltip interaction="persistent" popoverPlacement="bottom">
              <span slot="trigger">
                {t('MessagesPlus.empty_state.tooltip.text')}
              </span>
              <div
                slot="content"
                className="UpgradeEmptyState__tooltip-content"
              >
                <p className="UpgradeEmptyState__tooltip-text">
                  <Trans
                    i18nKey="MessagesPlus.empty_state.tooltip.popover_text"
                    components={{
                      1: (
                        <MarketLink
                          href={M_PLUS_SUBSCRIPTION_FAQ_URL}
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </p>
                <MarketLink
                  href={M_PLUS_SUBSCRIPTION_FAQ_URL}
                  target="_blank"
                  onClick={() => {
                    event.track('Click CBD Learn More', {
                      referral_page_name: referralPageName,
                    });
                  }}
                  className="UpgradeEmptyState__tooltip__link"
                >
                  <span className="UpgradeEmptyState__tooltip-cta">
                    {t('common.learn_more')}
                  </span>
                </MarketLink>
              </div>
            </MarketTooltip>
          )}
        </div>
      </MarketEmptyState>
    );
  },
);

export default UpgradeEmptyState;
