import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MESSAGES_MARKET_COMPONENT_PREFIX,
  MarketButton,
  MarketLink,
  MarketList,
  MarketRow,
  MarketTable,
  MarketTableColumn,
  MarketTableRow,
} from 'src/components/Market';
import useSavedSubscriptionPaymentMethod from 'src/hooks/useSavedSubscriptionPaymentMethod';
import usePreventAccessIfProhibited from 'src/hooks/usePreventAccessIfProhibited';
import {
  MessengerModalFullContent,
  MessengerModalFullHeader,
} from 'src/components/MessengerModalFull';
import UnitInfoCell from 'src/pages/UnitsToVerifyPage/components/UnitInfoCell/UnitInfoCell';
import './UnitsToVerifyPage.scss';
import useIsMobile from 'src/hooks/useIsMobile';
import { combineFieldForReviewAndUnitInformation } from './utils';
import { marketComponentsOnReady } from 'src/utils/renderUtils';

/**
 * The primary Market component that defines the height of this component.
 */
const MAIN_MARKET_COMPONENT_TAG = `${MESSAGES_MARKET_COMPONENT_PREFIX}-market-row`;

export type UnitsToVerifyPageProps = {
  unitTokens: string[];
};

/**
 * Top level page component that displays the sheet page with a table of units
 * and their corresponding information to be submitted for verification.
 *
 * @param {string[]} unitTokens
 * The unit tokens to display verification information for.
 */
const UnitsToVerifyPage = observer(({ unitTokens }: UnitsToVerifyPageProps) => {
  const { t } = useTranslation();
  const { navigation, user, status, unitVerification, event } =
    useMessengerControllerContext();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const contentRef = useRef<HTMLElement>(null);

  usePreventAccessIfProhibited();
  useSavedSubscriptionPaymentMethod();

  /**
   * Handles the scroll position on mobile view so that we can return to the
   * previous position after navigating from the form.
   */
  useEffect(() => {
    const element = contentRef.current;
    if (!element) {
      return undefined;
    }

    marketComponentsOnReady(element, MAIN_MARKET_COMPONENT_TAG).then(() => {
      element.scrollTop = unitVerification.scrollPosition;
    });

    const handleScroll = (): void => {
      unitVerification.setScrollPosition(element.scrollTop);
    };
    element.addEventListener('scroll', handleScroll);

    return () => element.removeEventListener('scroll', handleScroll);
  }, [contentRef, unitVerification]);

  /**
   * Return to the pricing page if there are no units selected, e.g. when
   * the page url is directly entered without the ut query param
   */
  useEffect(() => {
    if (unitTokens.length === 0) {
      navigation.openSheet('MESSAGES_PLUS_PRICING');
    }
  }, [unitTokens, navigation]);

  /**
   * Loads the unit information if not already loaded.
   */
  useEffect(() => {
    const needsToLoadVerificationInfo = unitTokens.some(
      (unitToken) =>
        !user.units.get(unitToken)?.subscription?.verificationInfoToReview,
    );
    if (needsToLoadVerificationInfo) {
      unitVerification.getUnitsVerificationInformation(unitTokens);
    }
  }, [user, unitVerification, unitTokens]);

  /**
   * Trigger CDP event during initial render.
   */
  useEffect(() => {
    event.track('View Units with Missing Information', {
      unit_tokens: unitTokens,
    });
  }, [event, unitTokens]);

  const isLoading =
    unitVerification.status === 'NOT_STARTED' ||
    unitVerification.status === 'LOADING';

  const isMobile = useIsMobile();
  let content;
  const unitRowOnClick = (unitToken: string): void => {
    event.track('Click Units with Missing Information', {
      action_type_name: 'click_on_unit',
      unit_tokens: [unitToken],
    });
    navigation.openSheet({
      name: 'UNIT_VERIFICATION_FORM',
      unitToken,
    });
  };
  if (isMobile) {
    content = (
      <div data-testid="UnitsToVerifyPage__mobile-list">
        {unitTokens.map((unitToken) => {
          const unit = user.units.get(unitToken);
          const unitInformation =
            unit?.subscription?.verificationInfoToReview?.unitInformation;
          const missingFields =
            unit?.subscription?.verificationInfoToReview?.fields;

          if (!unitInformation) {
            return null;
          }

          const fields = combineFieldForReviewAndUnitInformation(
            unitInformation,
            user.countryCode,
          );

          return (
            <div onClick={() => unitRowOnClick(unitToken)} key={unitToken}>
              <div className="UnitsToVerifyPage__unit-name-row">
                <h2>{unit.name}</h2>
                <MarketLink>{t('common.edit')}</MarketLink>
              </div>
              <MarketList>
                {fields.map((field) => {
                  return (
                    <MarketRow key={field.names[0]}>
                      <label slot="label">{t(field.labelKey)}</label>
                      <label slot="side-label">
                        {field.names.some((name) =>
                          missingFields?.includes(name),
                        ) ? (
                          <span className="UnitInfoCell__missing">
                            {t('UnitsToVerifyPage.missing_label')}
                          </span>
                        ) : (
                          field.value
                        )}
                      </label>
                    </MarketRow>
                  );
                })}
              </MarketList>
            </div>
          );
        })}
      </div>
    );
  } else {
    content = (
      <MarketTable data-testid="UnitsToVerifyPage__table">
        <MarketTableRow slot="header">
          <MarketTableColumn name="location">
            {t('UnitsToVerifyPage.location_col_title')}
          </MarketTableColumn>
          <MarketTableColumn name="address">
            {t('UnitsToVerifyPage.address_col_title')}
          </MarketTableColumn>
          <MarketTableColumn name="contact">
            {t('UnitsToVerifyPage.contact_col_title')}
          </MarketTableColumn>
          <MarketTableColumn name="phone">
            {t('UnitsToVerifyPage.phone_col_title')}
          </MarketTableColumn>
          <MarketTableColumn name="email">
            {t('UnitsToVerifyPage.email_col_title')}
          </MarketTableColumn>
          <MarketTableColumn name="website">
            {t('UnitsToVerifyPage.website_col_title')}
          </MarketTableColumn>
        </MarketTableRow>

        {unitTokens.map((unitToken) => {
          const unit = user.units.get(unitToken);
          const unitInformation =
            unit?.subscription?.verificationInfoToReview?.unitInformation;
          const missingFields =
            unit?.subscription?.verificationInfoToReview?.fields;

          if (!unitInformation) {
            return null;
          }

          const fields = combineFieldForReviewAndUnitInformation(
            unitInformation,
            user.countryCode,
          );

          return (
            <MarketTableRow
              key={unitToken}
              interactive
              onMarketTableRowClicked={() => unitRowOnClick(unitToken)}
              data-testid={`UnitsToVerifyPage__table-row-${unitToken}`}
            >
              {fields.map((field) => {
                return (
                  <UnitInfoCell
                    key={field.names[0]}
                    isMissing={field.names.some((name) =>
                      missingFields?.includes(name),
                    )}
                  >
                    {field.value}
                  </UnitInfoCell>
                );
              })}
            </MarketTableRow>
          );
        })}
      </MarketTable>
    );
  }

  return (
    <>
      <MessengerModalFullHeader
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={async () => {
              event.track('Click Units with Missing Information', {
                action_type_name: 'next',
                unit_tokens: unitTokens,
              });
              try {
                setIsSubmitting(true);
                await unitVerification.saveUnitInformationAndNavigate(
                  unitTokens,
                );
                setIsSubmitting(false);
              } catch {
                setIsSubmitting(false);
              }
            }}
            disabled={
              unitVerification.isUnitInformationMissing(unitTokens) || undefined
            }
            isLoading={isSubmitting || undefined}
          >
            {t('UnitsToVerifyPage.button_label')}
          </MarketButton>
        }
        navigationVariant={navigation.sheet.canNavigateBack ? 'BACK' : 'CLOSE'}
        onNavigateClick={() => {
          if (navigation.sheet.canNavigateBack) {
            navigation.sheet.navigateBack();
          } else {
            navigation.closeSheet();
          }
          unitVerification.resetScrollPosition();
        }}
      />
      <MessengerModalFullContent
        status={status.value}
        isLoading={isLoading}
        ref={contentRef}
      >
        <div className="UnitsToVerifyPage__content">
          <div className="UnitsToVerifyPage__content-header-container">
            <div className="UnitsToVerifyPage__content__header">
              {t('UnitsToVerifyPage.title')}
            </div>
            <div className="UnitsToVerifyPage__content__desc">
              <Trans
                i18nKey="UnitsToVerifyPage.description"
                components={{
                  1: <span className="UnitsToVerifyPage__bold-text" />,
                }}
              />
            </div>
          </div>
          {content}
        </div>
      </MessengerModalFullContent>
    </>
  );
});

export default UnitsToVerifyPage;
