import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import './VerificationFailedDialog.scss';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import useIsMobile from 'src/hooks/useIsMobile';

/**
 * Dialog to alert the user that TFN verification failed for their unit and that
 * they need to review and resubmit information.
 */
const VerificationFailedDialog = observer((): ReactElement => {
  const { t } = useTranslation();
  const { tooltip, navigation, subscription, event } =
    useMessengerControllerContext();
  const isMobile = useIsMobile();

  useEffect(() => {
    event.track('View Verification Result');
  }, [event]);

  const unitToken = subscription.unitTokensFailedRetryable[0];

  return (
    <MessengerModalDialog
      title={t('VerificationFailedDialog.title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            event.track('Click Verification Result', {
              action_type_name: 'review_and_resubmit',
            });
            navigation.openSheet({
              name: 'UNIT_VERIFICATION_FORM',
              unitToken,
            });
            tooltip.dismiss('VERIFICATION_FAILED_DIALOG');
          }}
          data-cy="VerificationFailedDialog__resubmit_button"
        >
          {t('VerificationFailedDialog.resubmit_button_text')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton
          rank="secondary"
          onClick={() => {
            event.track('Click Verification Result', {
              action_type_name: 'not_now',
            });
            tooltip.dismiss('VERIFICATION_FAILED_DIALOG');
          }}
        >
          {t('VerificationFailedDialog.dismiss_button_text')}
        </MarketButton>
      }
      buttonGroupAlign={isMobile ? 'stack' : 'fill'}
    >
      <p
        className="VerificationFailedDialog__description paragraph-30"
        data-cy="VerificationFailedDialog__description"
      >
        {subscription.isEligibleForSquareOne
          ? t('VerificationFailedDialog.description_sqone')
          : t('VerificationFailedDialog.description')}
      </p>
    </MessengerModalDialog>
  );
});

export default VerificationFailedDialog;
