import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './InputFileCard.scss';
import 'src/pages/TranscriptViewPage/components/MessageInput/components/InputPhotoCard/InputPhotoCard.scss';
import GenericFileIcon from 'src/svgs/GenericFileIcon';
import ImageFileIcon from 'src/svgs/ImageFileIcon';
import PdfFileIcon from 'src/svgs/PdfFileIcon';
import { bytesToSizeLabel } from 'src/utils/fileUtils';
import { InputAttachmentCardProps } from 'src/pages/TranscriptViewPage/components/MessageInput/components/InputPhotoCard/InputPhotoCard';
import { LocalFile } from 'src/MessengerTypes'; // do not delete, required for param comment
import {
  MarketAccessory,
  MarketContentCard,
  MarketRow,
} from 'src/components/Market';
import RemoveFileIcon from 'src/svgs/RemoveFileIcon';

// Extracts the extension of a file name.
// e.g. helloworld.pdf -> .pdf
const FILE_EXTENSION_REGEX = /\.(?:.(?!\.))+$/g;

/**
 * A card representing a file pending upload when the message is sent.
 *
 * @param {LocalFile} file
 * Photo file to render.
 * @param {Function} onRemove
 * Function to call when 'X' is clicked.
 * @author klim
 */
const InputFileCard = observer(
  (props: InputAttachmentCardProps): ReactElement => {
    const { file, onRemove } = props;

    let icon;
    switch (file.file.type) {
      case 'application/pdf':
        icon = <PdfFileIcon />;
        break;
      case 'image/png':
      case 'image/jpeg':
      case 'image/tiff':
      case 'image/gif':
        icon = <ImageFileIcon />;
        break;
      default:
        icon = <GenericFileIcon />;
    }

    const fileExtension = file.file.name.match(FILE_EXTENSION_REGEX)?.[0] ?? '';
    const fileName = file.file.name.replace(fileExtension, '');

    return (
      <MarketContentCard className="InputFileCard" data-testid="InputFileCard">
        <MarketRow size="small">
          <MarketAccessory
            className="EventCard__icon"
            slot="leading-accessory"
            size="image"
          >
            {icon}
          </MarketAccessory>
          <label data-testid="InputFileCard__label" slot="label">
            <span className="InputFileCard__name">{fileName}</span>
            {fileExtension}
          </label>
          <p className="EventCard__row__subtext" slot="subtext">
            {bytesToSizeLabel(file.file.size)}
          </p>
          <MarketAccessory
            slot="trailing-accessory"
            className="InputFileCard__remove"
            data-testid="InputFileCard__remove"
            onClick={() => onRemove(file)}
          >
            <RemoveFileIcon />
          </MarketAccessory>
        </MarketRow>
      </MarketContentCard>
    );
  },
);

export default InputFileCard;
