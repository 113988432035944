import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MessengerController from './MessengerController';
import MessengerControllerContext from './context/MessengerControllerContext';
import FullPageApp from './FullPageApp';
import { SaaSProvider } from '@squareup/saas-shared-ui';
import { Currency } from './gen/squareup/connect/v2/common/money';

type FullPageAppContainerProps = {
  messenger: MessengerController;
};

/**
 * The React component root for the Messages Full Page Application.
 *
 * @param {FullPageAppContainerProps} props
 * @param {MessengerController} props.messenger
 */
const FullPageAppContainer = observer(
  ({ messenger }: FullPageAppContainerProps): ReactElement => {
    return (
      <MessengerControllerContext.Provider value={messenger}>
        <SaaSProvider
          merchantToken={undefined}
          currencyCode={
            (messenger.user.currencyCode as keyof typeof Currency) || 'USD'
          }
          countryCode={messenger.user.countryCode}
          locale={messenger.user.locale}
        >
          <FullPageApp />
        </SaaSProvider>
      </MessengerControllerContext.Provider>
    );
  },
);

export default FullPageAppContainer;
