/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';
import './messenger_service';
import '../../rolodex/external';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "messenger": {
        "nested": {
          "v3": {
            "nested": {
              "auxiliary": {
                "nested": {
                  "Status": {
                    "fields": {
                      "code": {
                        "type": "squareup.messenger.v3.auxiliary.Status.Code",
                        "id": 1,
                        "rule": "optional"
                      },
                      "speleoTraceId": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "Code": {
                        "values": {
                          "STATUS_CODE_UNRECOGNIZED": 0,
                          "SUCCESS": 1,
                          "FAILED": 2,
                          "UNAUTHORIZED": 3,
                          "FORBIDDEN": 4
                        }
                      }
                    }
                  },
                  "MerchantCompletedIdvRequest": {
                    "fields": {}
                  },
                  "MerchantCompletedIdvResponse": {
                    "fields": {
                      "status": {
                        "type": "squareup.messenger.v3.auxiliary.Status",
                        "id": 1,
                        "rule": "optional"
                      },
                      "completedIdv": {
                        "type": "bool",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "Contact": {
                    "fields": {
                      "token": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "name": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "initials": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "transactionCount": {
                        "type": "int64",
                        "id": 4,
                        "rule": "optional"
                      },
                      "totalGpv": {
                        "type": "squareup.connect.v2.common.Money",
                        "id": 5,
                        "rule": "optional"
                      },
                      "contactMethods": {
                        "type": "squareup.messenger.v3.auxiliary.Contact.ContactMethod",
                        "id": 6,
                        "rule": "repeated"
                      }
                    },
                    "nested": {
                      "ContactMethod": {
                        "fields": {
                          "medium": {
                            "type": "squareup.messenger.v3.Medium",
                            "id": 1,
                            "rule": "optional"
                          },
                          "contactId": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "contactHandle": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          },
                          "displayContact": {
                            "type": "string",
                            "id": 4,
                            "rule": "optional"
                          },
                          "isPrimary": {
                            "type": "bool",
                            "id": 5,
                            "rule": "optional"
                          },
                          "maskedContact": {
                            "type": "string",
                            "id": 6,
                            "rule": "optional"
                          }
                        }
                      }
                    }
                  },
                  "GetContactsRequest": {
                    "fields": {
                      "contactTokens": {
                        "type": "string",
                        "id": 1,
                        "rule": "repeated"
                      },
                      "includeBuyerSummary": {
                        "type": "bool",
                        "id": 2,
                        "rule": "optional",
                        "options": {
                          "default": false
                        }
                      }
                    }
                  },
                  "GetContactsResponse": {
                    "fields": {
                      "status": {
                        "type": "squareup.messenger.v3.auxiliary.Status",
                        "id": 1,
                        "rule": "optional"
                      },
                      "contacts": {
                        "type": "squareup.messenger.v3.auxiliary.Contact",
                        "id": 2,
                        "rule": "repeated"
                      }
                    }
                  },
                  "Uint32Wrapper": {
                    "fields": {
                      "value": {
                        "type": "uint32",
                        "id": 1,
                        "rule": "optional"
                      }
                    }
                  },
                  "SearchContactsRequest": {
                    "fields": {
                      "query": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "limit": {
                        "type": "squareup.messenger.v3.auxiliary.Uint32Wrapper",
                        "id": 2,
                        "rule": "optional"
                      },
                      "sortType": {
                        "type": "squareup.rolodex.ListContactsSortType",
                        "id": 3,
                        "rule": "optional"
                      },
                      "includeBuyerSummary": {
                        "type": "bool",
                        "id": 4,
                        "rule": "optional",
                        "options": {
                          "default": false
                        }
                      },
                      "pagingKey": {
                        "type": "string",
                        "id": 5,
                        "rule": "optional"
                      }
                    }
                  },
                  "SearchContactsResponse": {
                    "fields": {
                      "status": {
                        "type": "squareup.messenger.v3.auxiliary.Status",
                        "id": 1,
                        "rule": "optional"
                      },
                      "contacts": {
                        "type": "squareup.messenger.v3.auxiliary.Contact",
                        "id": 2,
                        "rule": "repeated"
                      },
                      "pagingKey": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      }
                    }
                  },
                  "CreateContactRequest": {
                    "fields": {
                      "emailAddress": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "phoneNumber": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "givenName": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "surname": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      }
                    }
                  },
                  "CreateContactResponse": {
                    "fields": {
                      "status": {
                        "type": "squareup.messenger.v3.auxiliary.Status",
                        "id": 1,
                        "rule": "optional"
                      },
                      "contact": {
                        "type": "squareup.messenger.v3.auxiliary.Contact",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "MessengerAuxiliaryService": {
                    "methods": {
                      "MerchantCompletedIdv": {
                        "requestType": "squareup.messenger.v3.auxiliary.MerchantCompletedIdvRequest",
                        "responseType": "squareup.messenger.v3.auxiliary.MerchantCompletedIdvResponse"
                      },
                      "GetContacts": {
                        "requestType": "squareup.messenger.v3.auxiliary.GetContactsRequest",
                        "responseType": "squareup.messenger.v3.auxiliary.GetContactsResponse"
                      },
                      "SearchContacts": {
                        "requestType": "squareup.messenger.v3.auxiliary.SearchContactsRequest",
                        "responseType": "squareup.messenger.v3.auxiliary.SearchContactsResponse"
                      },
                      "CreateContact": {
                        "requestType": "squareup.messenger.v3.auxiliary.CreateContactRequest",
                        "responseType": "squareup.messenger.v3.auxiliary.CreateContactResponse"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.Status && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.Status?.nested?.Code && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.MerchantCompletedIdvRequest && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.MerchantCompletedIdvResponse && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.Contact && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.Contact?.nested?.ContactMethod && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.GetContactsRequest && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.GetContactsResponse && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.Uint32Wrapper && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.SearchContactsRequest && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.SearchContactsResponse && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.CreateContactRequest && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.CreateContactResponse && !$root.nested?.squareup?.nested?.messenger?.nested?.v3?.nested?.auxiliary?.nested?.MessengerAuxiliaryService) {
	$root.addJSON(jsonData);
}

/**
 * The first field in any response from MessengerAuxiliaryService. Check
 * this field to determine whether the request failed. This is separate from the
 * MessengerService Status data structure because this service is not
 * expected to return most of those status types.
 *
 * SOURCE Status @ squareup/messenger/v3/messenger_auxiliary_service.proto at 21:1
 */
export const Status = $root.lookupType('squareup.messenger.v3.auxiliary.Status');
fixType(Status, {
  code: { required: true },
});
Builder.createAndAttachToType(Status);
/**
 * SOURCE Code @ squareup/messenger/v3/messenger_auxiliary_service.proto at 22:3
 */
Status.Code = $root.lookupEnum('squareup.messenger.v3.auxiliary.Status.Code').values;
/**
 * Request proto for MerchantCompletedIdv. Note that the request is empty
 * but the merchant token will be inferred from the multipass session. We
 * do not expose an internal version of this endpoint.
 *
 * SOURCE MerchantCompletedIdvRequest @ squareup/messenger/v3/messenger_auxiliary_service.proto at 77:1
 */
export const MerchantCompletedIdvRequest = $root.lookupType('squareup.messenger.v3.auxiliary.MerchantCompletedIdvRequest');
fixType(MerchantCompletedIdvRequest);
Builder.createAndAttachToType(MerchantCompletedIdvRequest);
/**
 * Response proto for MerchantCompletedIdv.
 *
 * SOURCE MerchantCompletedIdvResponse @ squareup/messenger/v3/messenger_auxiliary_service.proto at 82:1
 */
export const MerchantCompletedIdvResponse = $root.lookupType('squareup.messenger.v3.auxiliary.MerchantCompletedIdvResponse');
fixType(MerchantCompletedIdvResponse, {
  status: { required: true },
  completedIdv: { required: true },
});
Builder.createAndAttachToType(MerchantCompletedIdvResponse);
/**
 * A representation of a contact from Rolodex for Messenger's purposes. The main difference from
 * Rolodex is that we provide a contact_handle, which is helpful when the caller does not have
 * permission to see the contact_id; contact_handle can also be used to load transcripts or send
 * outbounds from the main Messenger service.
 *
 * SOURCE Contact @ squareup/messenger/v3/messenger_auxiliary_service.proto at 108:1
 */
export const Contact = $root.lookupType('squareup.messenger.v3.auxiliary.Contact');
fixType(Contact);
Builder.createAndAttachToType(Contact);
/**
 * A wrapper around medium/contactId pairs.
 *
 * SOURCE ContactMethod @ squareup/messenger/v3/messenger_auxiliary_service.proto at 112:3
 */
Contact.ContactMethod = $root.lookupType('squareup.messenger.v3.auxiliary.Contact.ContactMethod');
fixType(Contact.ContactMethod);
Builder.createAndAttachToType(Contact.ContactMethod);
/**
 * Request proto for GetContacts; fetches contact information from Rolodex for one or more contact
 * tokens. The merchant token will be inferred from the multipass session.
 *
 * SOURCE GetContactsRequest @ squareup/messenger/v3/messenger_auxiliary_service.proto at 183:1
 */
export const GetContactsRequest = $root.lookupType('squareup.messenger.v3.auxiliary.GetContactsRequest');
fixType(GetContactsRequest, {
  contactTokens: { required: true },
});
Builder.createAndAttachToType(GetContactsRequest);
/**
 * Response proto for GetContacts.
 *
 * SOURCE GetContactsResponse @ squareup/messenger/v3/messenger_auxiliary_service.proto at 200:1
 */
export const GetContactsResponse = $root.lookupType('squareup.messenger.v3.auxiliary.GetContactsResponse');
fixType(GetContactsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(GetContactsResponse);
/**
 * A wrapper around a primitive uint32. This is needed for endpoints where an unset uint32 from the
 * caller is semantically different than `0`, since Wire (proto-to-source generator for clients)
 * doesn't support leaving primitive fields unset.
 *
 * SOURCE Uint32Wrapper @ squareup/messenger/v3/messenger_auxiliary_service.proto at 214:1
 */
export const Uint32Wrapper = $root.lookupType('squareup.messenger.v3.auxiliary.Uint32Wrapper');
fixType(Uint32Wrapper, {
  value: { required: true },
});
Builder.createAndAttachToType(Uint32Wrapper);
/**
 * Request proto for SearchContacts; searches for Rolodex contacts by name, email, or phone number.
 * The merchant token will be inferred from the multipass session. We do not expose an internal
 * version of this endpoint.
 *
 * SOURCE SearchContactsRequest @ squareup/messenger/v3/messenger_auxiliary_service.proto at 226:1
 */
export const SearchContactsRequest = $root.lookupType('squareup.messenger.v3.auxiliary.SearchContactsRequest');
fixType(SearchContactsRequest);
Builder.createAndAttachToType(SearchContactsRequest);
/**
 * Response proto for SearchContacts.
 *
 * SOURCE SearchContactsResponse @ squareup/messenger/v3/messenger_auxiliary_service.proto at 262:1
 */
export const SearchContactsResponse = $root.lookupType('squareup.messenger.v3.auxiliary.SearchContactsResponse');
fixType(SearchContactsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(SearchContactsResponse);
/**
 * Request proto for CreateContact; creates a Contact in Rolodex.
 *
 * SOURCE CreateContactRequest @ squareup/messenger/v3/messenger_auxiliary_service.proto at 280:1
 */
export const CreateContactRequest = $root.lookupType('squareup.messenger.v3.auxiliary.CreateContactRequest');
fixType(CreateContactRequest);
Builder.createAndAttachToType(CreateContactRequest);
/**
 * SOURCE CreateContactResponse @ squareup/messenger/v3/messenger_auxiliary_service.proto at 302:1
 */
export const CreateContactResponse = $root.lookupType('squareup.messenger.v3.auxiliary.CreateContactResponse');
fixType(CreateContactResponse, {
  status: { required: true },
  contact: { required: true },
});
Builder.createAndAttachToType(CreateContactResponse);
/**
 * Endpoints to allow the Messages frontend to fetch external data from
 * other Square services, proxied through MessengerService. This is the
 * "2024 and beyond" version of MessengerService#ExecuteGraphQL.
 *
 * This service is separate from MessengerService because it is expected
 * to be light wrappers around other RPCs and not core Messages functionality.
 * It has less rigorous monitoring and performance testing needs than the
 * endpoints that MessengerService exposes.
 *
 * SOURCE MessengerAuxiliaryService @ squareup/messenger/v3/messenger_auxiliary_service.proto at 321:1
 */
export const MessengerAuxiliaryService = $root.lookupService('squareup.messenger.v3.auxiliary.MessengerAuxiliaryService');
