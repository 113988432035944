import React, { ReactElement, PropsWithChildren } from 'react';

// This is a mock of the @squareup/saas-shared-ui library.
// It is called in setupTests.ts to automatically setup the mock for all tests.

type SaaSProviderProps = {
  merchantToken: string | undefined;
  currencyCode: string;
  countryCode: string;
  locale: string;
};

const SaaSProvider = ({
  children,
}: PropsWithChildren<SaaSProviderProps>): ReactElement => {
  return <>{children}</>;
};

const Feature = {
  MESSAGES_PLUS: 76,
};

const UpsellPaywallFullPage = (): ReactElement => (
  <div>TestUpsellPaywallFullPage</div>
);

const UpsellPaywallCard = (): ReactElement => <div>TestUpsellPaywallCard</div>;

const UpsellPill = (): ReactElement => <div>TestUpsellPill</div>;

export {
  SaaSProvider,
  Feature,
  UpsellPaywallFullPage,
  UpsellPaywallCard,
  UpsellPill,
};
