import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './UpsellPaywallFullPageEmptyState.scss';
import { MarketEmptyState } from 'src/components/Market';
import { Feature, UpsellPaywallFullPage } from '@squareup/saas-shared-ui';

/**
 * SquareOne upsell paywall full page empty state.
 * Rendered when no transcript is selected.
 */
const UpsellPaywallFullPageEmptyState = observer((): ReactElement => {
  // TODO (teresalin): Add onActivate callback, test modal and upsell flow
  return (
    <MarketEmptyState
      className="UpsellPaywallFullPageEmptyState"
      data-testid="UpsellPaywallFullPageEmptyState"
    >
      <UpsellPaywallFullPage feature={Feature.MESSAGES_PLUS} />
    </MarketEmptyState>
  );
});

export default UpsellPaywallFullPageEmptyState;
