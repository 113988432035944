import { useSubscriptionFeatureStatus } from '@squareup/saas-shared-ui/dist/feature-gating';
import { Feature } from '@squareup/saas-shared-ui';
import { useEffect } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Custom hook to read and save the MESSAGES_PLUS feature statuses
 * to the SubscriptionStore.
 *
 * @returns {object} isFeatureEntitled and showFeatureUpsell
 * isFeatureEntitled {boolean} - Whether the user is entitled to access this feature.
 * showFeatureUpsell {boolean} - Whether the user is eligible for SQ1 and should be shown
 * an upsell for this feature.
 * isFeatureInTrial {boolean} - Whether the user is in a free trial for Square one.
 */
const useSaasFeatureStatus = (): {
  isFeatureEntitled: boolean;
  showFeatureUpsell: boolean;
  isFeatureInTrial: boolean;
} => {
  const { subscription } = useMessengerControllerContext();
  const { isFeatureEntitled, showFeatureUpsell, isFeatureInTrial } =
    useSubscriptionFeatureStatus(Feature.MESSAGES_PLUS);

  useEffect(() => {
    subscription.setIsFeatureEntitled(isFeatureEntitled);
    subscription.setShowFeatureUpsell(showFeatureUpsell);
    subscription.setIsFeatureInTrial(isFeatureInTrial);
  }, [isFeatureEntitled, showFeatureUpsell, isFeatureInTrial, subscription]);

  return {
    isFeatureEntitled,
    showFeatureUpsell,
    isFeatureInTrial,
  };
};

export default useSaasFeatureStatus;
