import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketEmptyState } from 'src/components/Market';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import './NoMessagesSentEmptyState.scss';

export type NoMessagesSentEmptyStateProps = {
  customerName: string;
};

/**
 * An empty state to show that there are no messages between the merchant
 * and the customer.
 *
 * @author klim
 */
const NoMessagesSentEmptyState = observer(
  ({ customerName }: NoMessagesSentEmptyStateProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <MarketEmptyState
        className="NoMessagesSentEmptyState"
        data-testid="NoMessagesSentEmptyState"
      >
        <h2 slot="primary-text">
          <div className="NoMessagesSentEmptyState__icon">
            <ChatBubblesIcon />
          </div>
          {t('NoMessagesSentEmptyState.title')}
        </h2>
        <p className="paragraph-30" slot="secondary-text">
          {t('NoMessagesSentEmptyState.description', {
            customer: customerName,
          })}
        </p>
      </MarketEmptyState>
    );
  },
);

export default NoMessagesSentEmptyState;
