import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketLink } from 'src/components/Market';
import { BUSINESS_LOCATIONS_URL } from 'src/utils/url';
import './SquareOneBusinessNumbersDescription.scss';

/**
 * Component that renders the description for the business numbers section of the settings page.
 * Note that this component does not check for Square One Eligibility, as this is done externally.
 */
const SquareOneBusinessNumbersDescription = observer((): ReactElement => {
  const { user, subscription, navigation } = useMessengerControllerContext();
  const { t } = useTranslation();

  if (subscription.isMessagesFeatureEnabledWithSquareOne) {
    if (user.unitsWithPendingOrDedicatedNumbers.length === 0) {
      return (
        <p
          className="paragraph-30 SquareOneBusinessNumbersDescription__text"
          data-testid="SquareOneBusinessNumbersDescription__number-unclaimed"
        >
          <Trans
            i18nKey="SettingsPage.business_numbers.desc_number_unclaimed"
            components={{
              1: <MarketLink href={BUSINESS_LOCATIONS_URL} target="_self" />,
            }}
          />
        </p>
      );
    }

    if (
      user.unitsWithPendingOrDedicatedNumbers.length < user.activeUnits.length
    ) {
      return (
        <p
          className="paragraph-30 SquareOneBusinessNumbersDescription__text"
          data-testid="SquareOneBusinessNumbersDescription__add-additional-number"
        >
          <Trans
            i18nKey="SettingsPage.business_numbers.desc_add_additional_number"
            components={{
              1: (
                <MarketLink
                  onClick={() => {
                    navigation.openUnitVerificationView(
                      user.unitsReadyForVerification.map((unit) => unit.token),
                    );
                  }}
                />
              ),
              2: <MarketLink href={BUSINESS_LOCATIONS_URL} target="_self" />,
            }}
          />
        </p>
      );
    }

    return (
      <p
        className="paragraph-30 SquareOneBusinessNumbersDescription__text"
        data-testid="SquareOneBusinessNumbersDescription__business-locations"
      >
        <Trans
          i18nKey="SettingsPage.business_numbers.desc_business_locations"
          components={{
            1: <MarketLink href={BUSINESS_LOCATIONS_URL} target="_self" />,
          }}
        />
      </p>
    );
  }

  return (
    <p className="paragraph-30 SquareOneBusinessNumbersDescription__text">
      {t('SettingsPage.business_numbers.desc_sq_one_unsubscribed')}
    </p>
  );
});

export default SquareOneBusinessNumbersDescription;
