/**
 * 2 chat bubbles, used as null state for list page.
 */

import React, { ReactElement } from 'react';

function ChatBubblesIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
    >
      <title>Chat</title>
      <path
        fill="url(#ChatBubblesIcon__a)"
        d="M56 4H4C1.79 4 0 5.79 0 8v36c0 2.21 1.79 4 4 4h4v12l12-12h36c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4Z"
      />
      <path
        fill="#fff"
        d="M30 30a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-16 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm32 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
      <path
        fill="#121212"
        d="M40 32h52c2.21 0 4 1.79 4 4v36c0 2.21-1.79 4-4 4h-4v12L76 76H40c-2.21 0-4-1.79-4-4V36c0-2.21 1.79-4 4-4Z"
      />
      <path
        fill="#fff"
        d="M66 58a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-16 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm32 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
      <defs>
        <linearGradient
          id="ChatBubblesIcon__a"
          x1="44.45"
          x2="12.69"
          y1=".975"
          y2="55.985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAE2FF" />
          <stop offset=".75" stopColor="#006AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ChatBubblesIcon;
