import React, { ReactElement, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import MessageInput from './components/MessageInput/MessageInput';
import RequestConsentModal from './components/RequestConsentModal/RequestConsentModal';
import SendLimitModal from './components/SendLimitModal/SendLimitModal';
import PhotosGallery from './components/PhotosGallery/PhotosGallery';
import ConfirmBlockModal from './components/ConfirmBlockModal';
import MessageBlockedModal from './components/MessageBlockedModal';
import UnverifiedModal from './components/UnverifiedModal/UnverifiedModal';
import TranscriptViewSkeletonState from './components/TranscriptViewSkeletonState/TranscriptViewSkeletonState';
import useTranscriptErrorHandling from './hooks/useTranscriptErrorHandling';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import TranscriptViewItemsList from './components/TranscriptViewItemsList/TranscriptViewItemsList';
import TranscriptViewHeader from './components/TranscriptViewHeader/TranscriptViewHeader';
import { Transition } from 'src/MessengerTypes';
import OutageBanner from 'src/pages/TranscriptsListPage/components/OutageBanner/OutageBanner';
import UpgradeEmptyState from 'src/components/UpgradeEmptyState/UpgradeEmptyState';
import ProhibitedState from './components/ProhibitedState/ProhibitedState';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import CreateCustomerSuggestionModal from './components/CreateCustomerSuggestionModal/CreateCustomerSuggestionModal';
import PaymentAlternativesModal from './components/PaymentAlternativesModal/PaymentAlternativesModal';
import VerificationInProgressState from './components/VerificationInProgressState/VerificationInProgressState';
import { MarketEmptyState } from 'src/components/Market';
import MessagesPluginLaunchBanner from 'src/components/MessagesPluginLaunchBanner/MessagesPluginLaunchBanner';
import useIsMobile from 'src/hooks/useIsMobile';
import './TranscriptViewPage.scss';
import FileUnsupportedFormatModal from './components/FileUnsupportedFormatModal/FileUnsupportedFormatModal';
import FileVirusDetectedModal from './components/FileVirusDetectedModal/FileVirusDetectedModal';
import BlockedEmptyState from './components/BlockedEmptyState/BlockedEmptyState';
import VerificationFailedRetryableState from './components/VerificationFailedRetryableState/VerificationFailedRetryableState';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import NoMessagesSentEmptyState from 'src/components/NoMessagesSentEmptyState/NoMessagesSentEmptyState';

export type TranscriptViewPageProps = {
  transitionDirection: Transition;
};

/**
 * Displays all the utterances and pins a MessageInput
 * at the bottom if the user is able to write messages.
 *
 * @example <TranscriptViewPage transitionDirection="RIGHT" />
 * @param {boolean} transitionDirection
 * Determines which direction the content slides in from.
 */
const TranscriptViewPage = observer(
  ({ transitionDirection }: TranscriptViewPageProps): ReactElement => {
    const { t } = useTranslation();
    const {
      transcriptView,
      navigation,
      modal,
      status,
      user,
      event,
      subscription,
      tooltip,
    } = useMessengerControllerContext();
    const isMobile = useIsMobile();
    const {
      transcript,
      onTransitionEnd,
      isLoading,
      title,
      isMissingSubscriptionOrNumber,
      isPendingOrFailedNonretryableNotProhibited,
      isFailedRetryable,
      mostRecentMessagesPluginEntryUtterance,
    } = transcriptView;

    const {
      isProhibited,
      isEligibleForSquareOne,
      isMessagesFeatureEnabledWithSquareOne,
    } = subscription;

    // The ref to the photo/file input, used in MessageInput and Suggestions
    const photoInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    // Display an error if selectedTranscriptId is missing or if the transcript failed to load
    useTranscriptErrorHandling();

    // Set up main content of the page
    let content;
    if (transcript.viewItems.length === 0) {
      if (isMissingSubscriptionOrNumber && isProhibited) {
        content = <ProhibitedState />;
      } else if (
        isFailedRetryable &&
        (!isEligibleForSquareOne || isMessagesFeatureEnabledWithSquareOne)
      ) {
        content = (
          <VerificationFailedRetryableState
            unitTokens={[transcript.sellerKey]}
          />
        );
      } else if (
        isPendingOrFailedNonretryableNotProhibited &&
        (!isEligibleForSquareOne || isMessagesFeatureEnabledWithSquareOne)
      ) {
        content = <VerificationInProgressState />;
      } else if (isMissingSubscriptionOrNumber) {
        if (isEligibleForSquareOne) {
          // For both the upgrade and claim number states,
          // we show the same empty state but with a banner above the input bar
          content = (
            <NoMessagesSentEmptyState
              customerName={
                transcript.displayName.name ??
                t('TranscriptViewPage.unknown_user')
              }
            />
          );
        } else {
          content = <UpgradeEmptyState unitToken={transcript.sellerKey} />;
        }
      } else if (transcript.isBlocked) {
        content = <BlockedEmptyState />;
      } else {
        content = (
          <div className="TranscriptViewPage__null-state">
            <MarketEmptyState>
              <div slot="media">
                <ChatBubblesIcon />
              </div>
              <h3 slot="primary-text">
                {t('TranscriptViewPage.null_state', {
                  name: title,
                })}
              </h3>
            </MarketEmptyState>
          </div>
        );
      }
    } else {
      content = <TranscriptViewItemsList photoInputRef={photoInputRef} />;
    }

    return (
      <>
        {title && <TranscriptViewHeader />}
        <MessengerContent
          transitionDirection={transitionDirection}
          onTransitionEnd={onTransitionEnd}
          // Hide status on the full page as otherwise both views on the full page show separate status banners
          status={!navigation.secondary.isOpen ? status.value : null}
        >
          <div className="TranscriptViewPage" data-testid="TranscriptViewPage">
            {!navigation.secondary.isOpen && <OutageBanner />}
            {isLoading ? (
              <div className="TranscriptViewPage__skeleton-state-container">
                <TranscriptViewSkeletonState showHeader={!title} />
              </div>
            ) : (
              content
            )}
            <MessageInput
              photoInputRef={photoInputRef}
              fileInputRef={fileInputRef}
            />
          </div>
          {modal.currentModal === 'CREATE_CUSTOMER_SUGGESTION' && (
            <CreateCustomerSuggestionModal />
          )}
          {modal.currentModal === 'REQUEST_CONSENT' && <RequestConsentModal />}
          {modal.currentModal === 'SEND_LIMIT' && <SendLimitModal />}
          {modal.currentModal === 'UNVERIFIED_IDENTITY' && <UnverifiedModal />}
          {modal.currentModal === 'PHOTOS_GALLERY' && <PhotosGallery />}
          {modal.currentModal === 'CONFIRM_BLOCK' && (
            <ConfirmBlockModal
              closeModal={modal.closeModal}
              onConfirm={() => {
                event.track('Click Confirm Block', {
                  merchant_token: user.merchantToken,
                  transcript_ids: [transcript.id],
                });
              }}
            />
          )}
          {modal.currentModal === 'MESSAGE_BLOCKED' && (
            <MessageBlockedModal closeModal={modal.closeModal} />
          )}
          {modal.currentModal === 'PAYMENT_ALTERNATIVES' && (
            <PaymentAlternativesModal />
          )}
          {modal.currentModal === 'FILE_UNSUPPORTED_FORMAT' && (
            <FileUnsupportedFormatModal />
          )}
          {modal.currentModal === 'FILE_VIRUS_DETECTED' && (
            <FileVirusDetectedModal />
          )}
          {(isMobile || navigation.application === 'BLADE') &&
            mostRecentMessagesPluginEntryUtterance &&
            tooltip.isVisible('MESSAGES_PLUGIN_LAUNCH_BANNER') && (
              <MessagesPluginLaunchBanner
                source={
                  mostRecentMessagesPluginEntryUtterance.metadata
                    ?.messagesPluginEntry?.urlLabelEnum ??
                  Utterance.Metadata.MessagesPluginEntry.UrlLabel
                    .URL_LABEL_UNRECOGNIZED
                }
                ftux={
                  mostRecentMessagesPluginEntryUtterance.metadata
                    ?.messagesPluginEntry?.ftux
                }
              />
            )}
        </MessengerContent>
      </>
    );
  },
);

export default TranscriptViewPage;
